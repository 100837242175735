import React, { useEffect } from 'react'

import { BottomSheet, BottomSheetProps } from '@/components/BottomSheet/bottom-sheet'
import { Text } from '@/components/Text'

import * as S from './styles'
import { ISafeIcon } from './Icon'

export type ISafeBottomSheetProps = {
  authenticate: () => Promise<void>
  onComplete: (success: boolean) => void
  title?: string | React.ReactNode
  description?: string | React.ReactNode
} & Pick<BottomSheetProps, 'onClose'>

export const ISafeBottomSheet = ({
  title,
  description,
  authenticate,
  onComplete,
  onClose,
}: ISafeBottomSheetProps) => {
  useEffect(() => {
    authenticate()
      .then(() => onComplete(true))
      .catch(() => onComplete(false))
      .finally(() => onClose())
  }, [authenticate, onComplete, onClose])

  return (
    <BottomSheet onClose={onClose}>
      <S.Container>
        <ISafeIcon />

        {title && React.isValidElement(title) ? (
          title
        ) : (
          <Text variant="headline-h3" semiBold>
            {title || 'Autenticação'}
          </Text>
        )}

        {description && React.isValidElement(description) ? (
          description
        ) : (
          <Text variant="body-3">
            {description || 'Para sua segurança estamos autenticando essa operação com o i-safe.'}
          </Text>
        )}
      </S.Container>
    </BottomSheet>
  )
}
