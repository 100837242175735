import styled, { css } from 'styled-components'

import * as Checkbox from '@/components/Checkbox/styles'

type StyledSwitchProps = {
  checked: boolean
}

const circleModifiers = {
  checked: () => css`
    transform: translate(110%, -50%);
  `,
}

const barModifiers = {
  checked: () => css`
    background-color: var(--switch-bar-checked-background-color);
  `,
}

export const Input = styled(Checkbox.Input)``

export const Label = styled(Checkbox.LabelText)``

export const Body = styled.div`
  position: relative;
  padding: 2px;
  pointer-events: none;
`

export const Bar = styled.div<StyledSwitchProps>`
  ${({ checked }) => css`
    background-color: var(--switch-bar-unchecked-background-color);
    border-radius: 48px;
    width: 34px;
    height: 20px;
    pointer-events: none;
    transition: all ease 0.2s;
    ${checked && barModifiers.checked}
  `}
`

export const Circle = styled.div<StyledSwitchProps>`
  ${({ checked }) => css`
    background-color: var(--switch-circle-background-color);
    transform: translate(25%, -50%);
    width: 16px;
    height: 16px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transition: all ease 0.2s;
    pointer-events: none;
    ${checked && circleModifiers.checked}
  `}
`

export const Container = styled(Checkbox.LabelContainer)`
  padding: 0;
  height: 24px;
`
