import React, { memo } from 'react'

import * as S from './styles'

export type CardProps = PropsWithRequiredChildren<DefaultHTMLAttrs<HTMLDivElement>>

export const Component = ({ children, ...attrs }: CardProps) => (
  <S.Container {...attrs}>{children}</S.Container>
)

export const Card = memo(Component)
