import styled, { css } from 'styled-components'

import { ContainerProps } from './types'

export const Flex = styled.div<ContainerProps>`
  box-sizing: border-box;
  display: flex;
  width: 100%;

  ${({ direction, alignItems, justifyContent, alignContent, gap }) => css`
    flex-direction: ${direction};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    align-content: ${alignContent};
    gap: ${gap};
  `}
`
