import styled, { css } from 'styled-components'
import ChevronMoreIcon from 'inter-frontend-svgs/lib/orangeds/XL/chevrondown'
import AddIcon from 'inter-frontend-svgs/lib/orangeds/XL/add'

import { Text as UIText } from '@/components/Text'

type Controls = { isNormal?: boolean; isActive?: boolean }

export const DROPDOWN_PADDING_CONFIG = '30px 0px 8px 0px'

export const ChevronMore = styled(ChevronMoreIcon).attrs({
  width: 12,
  height: 12,
})<{ isActive: boolean }>`
  path {
    stroke: ${({ isActive }) =>
      isActive ? 'var(--chips-active-text-color)' : 'var(--chips-text-color)'};
    stroke-width: 2;
  }
`

export const Add = styled(AddIcon).attrs({
  width: 6,
  height: 6,
})<{ isActive: boolean }>`
  path {
    stroke: ${({ isActive }) =>
      isActive ? 'var(--chips-active-text-color)' : 'var(--chips-text-color)'};
    stroke-width: 7;
  }
`

export const Container = styled.div<Controls>`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;

  border-radius: var(--radiiXlg);
`

export const Dropdown = styled.ul<Controls>`
  ${({ isActive }) => css`
    width: 100%;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${isActive
      ? 'var(--chips-active-background-color)'
      : 'var(--chips-background-color)'};
    list-style: none;
    border-radius: var(--chips-border-radius);
    min-height: var(--chips-height);

    overflow: hidden;
    transition: max-height ease 0.3s, padding ease 0.4s;
  `}
`

export const DropdownItem = styled.li<Controls>`
  ${({ isActive }) => css`
    cursor: pointer;
    width: fit-content;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: var(--chips-active-text-color);
    padding: var(--chips-dropdown-item-padding);

    & > span {
      color: ${isActive ? 'var(--chips-active-text-color)' : 'var(--chips-text-color)'};
    }
  `}
`

export const LabelContainer = styled.div<Controls>`
  ${({ isNormal, isActive }) => css`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    border-radius: var(--chips-border-radius);
    border: 1px solid var(--chips-border-color);
    background-color: ${isActive
      ? 'var(--chips-active-background-color)'
      : 'var(--chips-background-color)'};

    ${isNormal &&
    css`
      padding: 4px 16px;
    `}

    ${isActive &&
    css`
      & > div > span {
        color: var(--chips-active-text-color);
      }
    `}
  `}
`

export const CountCircle = styled.div<Controls>`
  ${({ isActive }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    min-width: 16px;
    width: fit-content;
    border-radius: var(--chips-counter-radius);
    background: ${isActive
      ? 'var(--chips-counter-active-background-color)'
      : 'var(--chips-counter-background-color)'};
    text-align: center;
    padding: 0px 3px;
    margin: 6px 16px 6px 8px;

    & > span {
      display: inline-block;
      color: ${isActive
        ? 'var(--chips-counter-active-text-color)'
        : 'var(--chips-counter-text-color)'};
    }
  `}
`

export const Text = styled(UIText).attrs({
  variant: 'caption-2',
  colorWeight: 500,
})<{ isSelectedItem?: boolean }>`
  font-size: 10px;
  font-weight: ${({ isSelectedItem }) => isSelectedItem && '900'};
`

export const PlusCircle = styled(CountCircle)<Controls>`
  ${({ isActive }) => css`
    background: ${isActive ? 'var(--gray400)' : 'var(--gray100)'};
    height: 24px;
    width: 24px;
    margin-right: 4px;
    margin-top: 2px;
    margin-bottom: 2px;
  `}
`

export const DropdownIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
`
