import React, { useCallback, useState, useRef, useEffect, useMemo, ReactNode } from 'react'

import { Text } from '@/components/Text'

import * as S from './styles'
import { ChipsProps, ChipsMode } from './types'

export type { ChipsProps, ChipsMode } from './types'

export const Chips = React.memo(
  ({
    label,
    variant = 'caption-2',
    mode,
    counter,
    isActive = false,
    options,
    selectedItem,
    onClickItem,
    onClick,
    ...attrs
  }: ChipsProps) => {
    const [isExpanded, setExpanded] = useState(false)
    const dropdownRef = useRef<HTMLUListElement>(null)
    const isNormal = useMemo(() => mode === 'normal', [mode])

    useEffect(() => {
      if (mode === 'dropdown' && dropdownRef.current) {
        dropdownRef.current.style.maxHeight = isExpanded ? '100vh' : 'var(--chips-height)'
        dropdownRef.current.style.padding = isExpanded ? S.DROPDOWN_PADDING_CONFIG : '0px'
      }
    }, [isExpanded, mode])

    const onClickContainer = useCallback(() => {
      if (isNormal) {
        onClick && onClick()
      } else {
        setExpanded((prevState) => !prevState)
      }
    }, [isNormal, onClick])

    const RenderDropdownMenu = useMemo(
      () => (
        <S.Dropdown ref={dropdownRef} isActive={isActive}>
          {options &&
            options.map((option) => {
              const isSelectedItem = !!selectedItem && option.value === selectedItem.value
              return (
                <S.DropdownItem
                  key={option.label}
                  data-testid="item"
                  isActive={isActive}
                  onClick={() => {
                    onClickItem?.(option)
                  }}
                >
                  <S.Text isSelectedItem={isSelectedItem}>{option.label}</S.Text>
                </S.DropdownItem>
              )
            })}
        </S.Dropdown>
      ),
      [isActive, onClickItem, options, selectedItem],
    )

    const rightIconMapper: Record<ChipsMode, ReactNode> = useMemo(
      () => ({
        normal: <></>,
        dropdown: (
          <S.DropdownIconContainer data-testid="arrow-down-icon">
            <S.ChevronMore isActive={isActive} />
          </S.DropdownIconContainer>
        ),
        counter: (
          <S.CountCircle isActive={isActive} data-testid="circle-icon">
            <S.Text>{counter}</S.Text>
          </S.CountCircle>
        ),
        plus: (
          <S.PlusCircle isActive={isActive} data-testid="plus-icon">
            <S.Add isActive={isActive} />
          </S.PlusCircle>
        ),
      }),
      [counter, isActive],
    )

    return (
      <S.Container {...attrs} isActive={isActive} onClick={onClickContainer}>
        {mode === 'dropdown' && RenderDropdownMenu}

        <S.LabelContainer isNormal={isNormal} isActive={isActive}>
          <div>
            <Text variant={variant} colorWeight={500}>
              {label}
            </Text>
          </div>

          <div>{rightIconMapper[mode]}</div>
        </S.LabelContainer>
      </S.Container>
    )
  },
)
