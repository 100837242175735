import React from 'react'

import * as S from './styles'
import { SpacingProps } from './types'

/**
 * Sizes:
 * - `xxxs`: 4px
 * - `xxs`: 8px
 * - `xs`: 16px
 * - `sm`: 24px
 * - `md`: 32px
 * - `lg`: 40px
 * - `xl`: 64px
 * - `xxl`: 96px
 * - `xxxl`: 128px
 *
 * Properties
 * - `m`: margin
 * - `mt`: margin-top
 * - `mr`: margin-right
 * - `mb`: margin-bottom
 * - `ml`: margin-left
 * - `mx`: margin-left, margin-right
 * - `my`: margin-top, margin-bottom
 * - `p`: padding
 * - `pt`: padding-top
 * - `pr`: padding-right
 * - `pb`: padding-bottom
 * - `pl`: padding-left
 * - `px`: padding-left, padding-right
 * - `py`: padding-top, padding-bottom
 */
export const Spacing = ({ children, ...rest }: SpacingProps) => (
  <S.Wrapper {...rest}>{children}</S.Wrapper>
)
