import React, { memo } from 'react'

import { CheckboxProps } from '@/components/Checkbox'

import * as S from './styles'

export type SwitchProps = Omit<CheckboxProps, 'indeterminate'>

export const Component = ({
  label = '',
  id,
  checked,
  disabled = false,
  onChange,
  reversed = false,
  ...attrs
}: SwitchProps) => (
  <S.Container disabled={disabled} htmlFor={id}>
    {reversed && label && (
      <S.Label reversed={reversed} data-testid="reversed-switch-label">
        {label}
      </S.Label>
    )}

    <S.Input
      {...attrs}
      id={id}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event)}
    />

    <S.Body>
      <S.Bar checked={!!checked} />
      <S.Circle checked={!!checked} />
    </S.Body>

    {!reversed && label && (
      <S.Label reversed={reversed} data-testid="switch-label">
        {label}
      </S.Label>
    )}
  </S.Container>
)

export const Switch = memo(Component)
