import React from 'react'

import * as S from './styles'
import * as Types from './types'

export const Flex = ({
  direction = 'column',
  justifyContent = 'space-between',
  alignItems = 'start',
  alignContent = 'start',
  gap,
  className,
  ...divProps
}: Types.FlexProps) => (
  <S.Flex
    direction={direction}
    justifyContent={justifyContent}
    alignItems={alignItems}
    alignContent={alignContent}
    className={className}
    gap={gap}
    {...divProps}
  />
)

export const FlexRow = (props: Omit<Types.FlexProps, 'direction'>) => (
  <Flex direction="row" {...props} />
)

export const FlexColumn = (props: Omit<Types.FlexProps, 'direction'>) => (
  <Flex direction="column" {...props} />
)
