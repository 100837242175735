import styled from 'styled-components'

import * as Types from './types'
import { commonStyles, DEFAULT_COLOR_WEIGHT, TITLE_COLOR_WEIGHT } from './styles'

export * from './types'

export const Text = styled.span<Types.TextProps>`
  ${({ color = 'typography', ...props }) => {
    let colorWeight: Types.ColorWeight = DEFAULT_COLOR_WEIGHT

    if (
      [
        'headline-master',
        'headline-super',
        'headline-big',
        'headline-h1',
        'headline-h2',
        'headline-h3',
      ].includes(props.variant)
    ) {
      colorWeight = TITLE_COLOR_WEIGHT
    }

    if (props.colorWeight) {
      colorWeight = props.colorWeight
    }

    return commonStyles({ color, colorWeight, ...props })
  }}
`
