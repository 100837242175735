import React, { useState, useRef, SyntheticEvent, useEffect } from 'react'
import { DatePickerStyle } from './style'
import { MaskDATE } from 'inter-frontend-lib-util-form/lib/masks/date'

// import './style.css'

import DatePicker from 'react-datepicker'
import pt from 'date-fns/locale/pt-BR'
import { addDays } from 'date-fns'

interface IDatePickerProps {
  dateFormat?: string;
  selected?: { start?: Date | null; end?: Date | null };
  EndDate?: boolean;
  hasDateLimit?: boolean;
  daysLimit?: number;
  maxDate?: Date;
  monthsShown?: number;
  minDate?: Date | null;
  size?: string;
  handleChangeStartDate?: (value: Date) => void;
  handleChangeEndDate?: (value: Date) => void;
}

const DatepickerComponent: React.FC<IDatePickerProps> =
({ dateFormat = 'dd/MM/yyyy', selected = { start: null, end: null }, EndDate = true, hasDateLimit, size = 'default',
  monthsShown = 2, daysLimit = 90, minDate = null, handleChangeStartDate, handleChangeEndDate, maxDate }: IDatePickerProps) => {
  const refContainer = useRef<any>(null)
  const [ startDate, setStartDate ] = useState<Date | null>(selected.start || null)
  const [ endDate, setEndDate ] = useState<Date | null>(selected.end || null)

  useEffect(() => {
    setStartDate(selected.start || null)
    setEndDate(selected.end || null)
  }, [ selected ])

  return (
    <DatePickerStyle size={size}>
      <DatePicker
        showPopperArrow={false}
        selected={startDate}
        locale={pt}
        useWeekdaysShort
        selectsStart
        dateFormat={[ dateFormat, 'd' ]}
        placeholderText='dd/mm/aaaa'
        monthsShown={monthsShown}
        startDate={startDate}
        minDate={minDate}
        maxDate={endDate || maxDate}
        endDate={endDate}
        onChangeRaw={(input: React.FormEvent<HTMLInputElement>) => { input.currentTarget.value = MaskDATE(input.currentTarget.value) }}
        onChange={(date: Date) => { setStartDate(date); if (handleChangeStartDate) { handleChangeStartDate(date) }; }}
        onCalendarClose={() => { if (refContainer.current && refContainer.current.input) refContainer.current.input.focus() }}
      />
      {EndDate &&
        <DatePicker
          ref={refContainer}
          showPopperArrow={false}
          selected={endDate}
          locale={pt}
          selectsEnd
          useWeekdaysShort
          dateFormat={dateFormat}
          minDate={(!!(startDate) && hasDateLimit) ? startDate : null}
          maxDate={(!!(startDate) && hasDateLimit) ? addDays(startDate, daysLimit) : null}
          startDate={startDate}
          endDate={endDate}
          monthsShown={monthsShown}
          placeholderText='dd/mm/aaaa'
          onChangeRaw={(e: SyntheticEvent) => { (e as any).target.value = MaskDATE((e as any).target.value) }}
          onChange={(date: Date) => { setEndDate(date); if (handleChangeEndDate) { handleChangeEndDate(date) }; }}
        />
      }

    </DatePickerStyle>
  )
}

export default DatepickerComponent
