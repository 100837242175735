import styled from 'styled-components'

import { DEFAULT_CONTAINER_PADDING } from '@/common/constants'

export const Container = styled.div`
  display: inline-block;
  position: relative;
  padding: ${DEFAULT_CONTAINER_PADDING}px;
  width: 100%;
  max-width: var(--card-max-width);
  min-height: 60px;
  border-radius: var(--card-border-radius);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  background-color: var(--card-background-color);
  color: var(--card-text-color);
`
